import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Test Week begins today Let’s set some PR’s!`}</em></strong></p>
    <p>{`Push Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Weighted Strict Pullups to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Jack”`}</strong></p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`10-Push Press (115/75)`}</p>
    <p>{`10-KBS’s (53/35)`}</p>
    <p>{`10-Box Jumps (24/20″)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`All gym members and their families are invited to our annual
Christmas party on Saturday, December 10th at 5:00pm at the Barnett
home. We will have a chili contest with a trophy up for grabs! If you’re
last name begins with A-M please bring a side dish, N-Z please bring a
dessert. Please RSVP to let us know if you will be bringing chili and
how many are attending and we’ll send you the address. RSVP to:
Daniel\\@crossfitlouisvilleeast.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      